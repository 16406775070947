import GET_ARTICLES from '../class/getArticles';
import ScrollObserver from '../class/scrollObserver';
import SplitText from '../class/splitText';
import { gsap } from 'gsap';


const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ]


class transparentHeader extends ScrollObserver {
  constructor(elm=d.querySelector('.rwd002-header'),opt) {
    opt = Object.assign({
      rootMargin: "0% 0px 0%", // ビューポートの中心を判定基準にする
    }, opt);
    super(opt);
    this.header = elm;
    this.trigger = d.querySelector('.mainvisual');
    this.observeElement();
  }
  observeElement () {
    this.observer = new IntersectionObserver( this.callback.bind(this), this.opt);
    this.observer.observe( this.trigger );
  }
  intersectIn ( entry, object ) {
    this.header.classList.add('is-transparent');
  }
  intersectOut ( entry, object ) {
    this.header.classList.remove('is-transparent');
  }
}

const domReady = TPL => {

  if( mq.matches ) new transparentHeader();

  //NEWS
  const news_block = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
  news_block.makeItem = (item,content) => `
  <li class="block-${content}-item">
    <a href="${item.href}" class="block-${content}-item__link">
      <div class="block-${content}-item__body">
        <div class="date-cate">
          <span class="date">${item.date}</span>
          <span class="cate">${item.category.name}</span>
          ${item.is_new ? '<span class="is-new">NEW</span>': ''}
        </div>
        <!-- /.date-cate -->
        <h4 class="subject">${item.title.str_truncate(50)}</h4>
      </div>
    </a>
  </li>`;
  news_block.render();

  //split text（メインビジュアル キャッチコピー用）
  const mySplitText = new SplitText("#mainvisual__title", {type:"chars"});
  const numChars = mySplitText.chars.length;
  
  const dispText = () => {
    const tl = gsap.timeline();
    for( let i = 0; i < numChars; i++ ) {
      tl.to( mySplitText.chars[i],{
        keyframes:[
          { duration: .2, opacity: 0, color: '#373CD2' },
          { duration: .2, opacity: 1, color: '#373CD2' },
          { duration: .2, color: '#FFF' },
        ]
      }, Math.random() * 1.2);
    }
  };

  dispText();
  d.querySelector('#mainvisual__title').addEventListener('mouseenter', () => dispText() );

};



const jqInit = () => {
  w.jQuery = $

  /****************************
   * movie background
   */
  $.when(
    w.load['loadHtml'],
    $.getScript('/assets/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]')
    const param = id => `{
      videoURL: '${id}',
      containment: '${(mq.matches)? '.mainvisual__wrap':'#allbox'}',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      abundance: '${(mq.matches)? 0.01: 0.15}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'center,center',
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param('aGWQmyluygQ'));
    $MV.YTPlayer();
    $MV.YTPPlay();
  });

  const getContentData = fetch(`/assets/api/getArticles/?content=story`).then( res => res.json() );
  getContentData.then((res) => { if(res.length == '1') $('.block-story__btnwrap').hide(); });
  
};


export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}



