import 'core-js';

// import './polyfill/object-assign'; // ie対策
// import './polyfill/object-values'; // ie対策
import './polyfill/customEvent'; // ie対策
import 'fetch-polyfill'; // ie対策
import 'url-search-params-polyfill'; // ie対策
import 'svgxuse'; // ie対策

import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import HOME from "./module/home";
import PROJECT from "./module/project";
import SERVICE from "./module/service";
import STORY from "./module/story";

const [w, d] = [window, document];

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

//IE対策
if (d.documentMode && w.MSInputMethodContext) {
  UTIL.addScript({'src':'/assets/js/vendor/ie11CustomProperties.js'});

  //bootstrap5対応
  UTIL.addScript({'src':'https://polyfill.io/v3/polyfill.min.js?features=Element.prototype.remove%2CElement.prototype.append%2CCustomEvent%2CEvent%2CElement.prototype.closest'});

  // Fix preventDefault for IE
  (function () {
    var workingDefaultPrevented = (function () {
      var e = document.createEvent('CustomEvent')
      e.initEvent('Bootstrap', true, true)
      e.preventDefault()
      return e.defaultPrevented
    })()
    if (!workingDefaultPrevented) {
      var origPreventDefault = Event.prototype.preventDefault
      Event.prototype.preventDefault = function () {
        if (!this.cancelable) {
          return
        }
        origPreventDefault.call(this)
        Object.defineProperty(this, 'defaultPrevented', {
          get: function () {
            return true
          },
          configurable: true
        })
      }
    }
  })()

}

// URLによる関数の実行
UTIL.loader( (request, path) => {
  switch( path ){
    case '/': HOME(); break
    case '/project/': PROJECT(); break
    case '/service/': SERVICE(); break
    case '/story/': STORY(); break
  }
  // switch( request ){}
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();