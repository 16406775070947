import { Modal } from "bootstrap";
import Utility from "../class/utility";
import LazyDisp from "../class/lazydisp";
import Promise from 'promise-polyfill'; // ie対策

const [ w, d, mq, l ] = [window, document, window.matchMedia( "(max-width: 900px)" ), location ]

const domReady = TPL => false;

const jqInit = () => {

  const UTIL = new Utility();



  Promise.all([w.load['loadHtml']]).then(() => {
    // LazyDisp開始
    new LazyDisp('[data-lazydisp]');
    if( l.hash ){
      const hash = l.hash.substring(1);
      l.hash = '';
      l.hash = hash;
    }
    if( $('body').hasClass('is--detail') ) {
      //ローカルナビブロック挿入
      const projectNav = $.get('/assets/html/nav-project.html');
      //詳細
      projectNav.done( html => {
        $('.rwd002-main').append(html);
        $('.rwd002-main .nav-project').addClass('is-foot');
        $('[data-plugin=currentMenu]').currentMenu({mode:0, default:999});
      });
    }
  });

  
  Promise.all([w.load['project-vision']]).then(() => {
    const modalBtn = $('a.team-list__item');

    if( !mq.matches ) {
      modalBtn.on('click', function () {
        const modalObj = new Modal( this.getAttribute('href') );
        modalObj.show();
        d.querySelector(this.getAttribute('href')).addEventListener('hidden.bs.modal', () => {
          d.body.style.overflow = "auto";
        });
        return false;
      });
    }
  });


};


export default function PROJECT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}