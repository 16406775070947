const [d] = [document];

export default class SplitText {
  constructor(selector,opt){
    this.obj = d.querySelector(selector);
    this.chars = '';
    this.words = '';
    switch ( true ){
      case /chars/i.test( opt.type ) : this.splitChar();
    }
  }
  
  splitChar () {
    let resultHtml = '';
    Array.prototype.forEach.call(this.obj.childNodes, node => {
      if (node.nodeType == 3) {//テキストの場合
        const text = node.textContent.replace(/\r?\n/g, '');
        //spanで囲んで連結    
        resultHtml += text.split('').reduce((acc, v) => acc + `<span class="js-splitText">${v}</span>`, '');
      } else {//テキスト以外
        //<br>などテキスト以外の要素をそのまま連結
        resultHtml += node.outerHTML;
      }
    });
    this.obj.innerHTML = resultHtml;
    this.chars = this.obj.querySelectorAll('span.js-splitText');
  }
}