import Utility from "../class/utility";
import LazyDisp from "../class/lazydisp";
import Promise from 'promise-polyfill'; // ie対策

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ]


const domReady = TPL => false;

const jqInit = () => {

  const UTIL = new Utility();


  
  Promise.all([w.load['loadHtml']]).then(() => {
    $('[data-plugin=currentMenu]').currentMenu({mode:0, default:999});

    // LazyDisp開始
    new LazyDisp('[data-lazydisp]');

    //ローカルナビブロック挿入
    const serviceNav = $.get('/assets/html/nav-service.html');
    serviceNav.done( html => {
      $('.rwd002-main').append(html);
      $('.rwd002-main .nav-project').addClass('is-foot');
      //currentMenu
      $('[data-plugin=currentMenu]').currentMenu({mode:0, default:999});
    });
  });

};


export default function SERVICE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}