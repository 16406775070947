import LazyDisp from "../class/lazydisp";
import Promise from 'promise-polyfill'; // ie対策

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ]

const domReady = TPL => false;

const jqInit = () => {
  Promise.all([ w.load['story-project01']]).then(() => {
    // LazyDisp開始
    new LazyDisp('[data-lazydisp]');
  });
};


export default function STORY () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}